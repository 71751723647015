import { useLazyQuery } from '@apollo/client/react';
import { DATO_CLIENT_NAME } from 'src/graphql/constants';

export const useLazyDatoCMSQuery = (query = '', queryOptions = {}) => {
  const options = {
    context: { clientName: DATO_CLIENT_NAME },
    ...queryOptions,
  };

  return useLazyQuery(query, options);
};
