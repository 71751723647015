import { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import GET_ARTICLE_CATEGORIES_DETAILS from 'src/graphql/queries/getArticleCategoriesDetails.gql';
import { NOT_LOGGED_IN_TAG_ID } from 'src/../config/personalizationTags';
import GET_SELECTED_ARTICLES from 'src/graphql/queries/getArticlesSelected.gql';
import GET_SELECTED_ARCHIVED_ARTICLES from 'src/graphql/queries/getArticlesArchivedSelected.gql';
import GET_SELECTED_ACTIVE_ARTICLES from 'src/graphql/queries/getArticlesActiveSelected.gql';
import { defaultLanguage } from 'config/locales';
import * as articlesTypes from 'config/articlesTypes';
import useDatoCMSQuery from '../useDatoCMSQuery';
import useLazyDatoCMSQuery from '../useLazyDatoCMSQuery';
import useArticlesCount from '../useArticlesCount';
import useLanguage from '../useLanguage';

export const useLazyPromiseDatoCMSQuery = (query, queryOption = {}) => {
  const resolveRef = useRef(() => {});
  const rejectRef = useRef(() => {});

  const [fetch, state] = useLazyDatoCMSQuery(query, {
    ...queryOption,
    onCompleted: (response) => {
      if (typeof queryOption?.onCompleted === 'function') {
        queryOption.onCompleted(response);
      }
      resolveRef.current(response);
    },
    onError: (error) => {
      if (typeof queryOption?.onError === 'function') {
        queryOption.onError(error);
      }
      rejectRef.current(error);
    },
    fetchPolicy: 'no-cache',
  });

  return [
    (option) => {
      rejectRef.current();
      return new Promise((resolve, reject) => {
        fetch(option);
        rejectRef.current = reject;
        resolveRef.current = resolve;
      });
    },
    state,
  ];
};

const articlesFetchQueries = {
  [articlesTypes.ACTIVE]: GET_SELECTED_ACTIVE_ARTICLES,
  [articlesTypes.ARCHIVED]: GET_SELECTED_ARCHIVED_ARTICLES,
  [articlesTypes.ALL]: GET_SELECTED_ARTICLES,
};

export const useArticlesList = (
  desiredArticlesType = articlesTypes.ACTIVE,
  {
    categories,
    tags,
    brandTags,
    productCategoriesTags,
    take,
    skip,
    thumbWidth,
    ssr = true,
    clearImage = false,
    user: userData,
    sortingByPersonalizationTags,
    paginationPageIdx,
    articlesCount,
    isPersonalizationEnabled,
  },
) => {
  const [locale] = useLanguage();
  const dateRef = useRef(new Date());

  const isLoggedIn = !!userData;

  const userPersonalizationTags = useMemo(() => {
    if (!isPersonalizationEnabled) {
      return undefined;
    }

    if (isLoggedIn) {
      return userData?.tags?.filter((tag) => !Number.isNaN(Number(tag))) || [];
    }

    return [NOT_LOGGED_IN_TAG_ID];
  }, [isLoggedIn, userData, isPersonalizationEnabled]);

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.setUTCHours(23, 59, 59, 999);
    }
  }, []);

  const [loadWithTagsArticlesCount, { articlesCount: articlesWithTagsCount }] = useArticlesCount({
    categories,
    tags,
    brandTags,
    productCategoriesTags,
    sortingByPersonalizationTags,
    personalizationTags: !isPersonalizationEnabled ? undefined : userPersonalizationTags,
    desiredArticlesType,
    archiveStartDate: dateRef.current.toISOString().split('T')[0],
  });

  if (!categories || !categories.length) {
    throw new Error("useArticlesList hook: Articles' categories have to be passed!");
  }

  const [allArticles, setAllArticles] = useState([]);

  const [loadFirstPartOfArticles, { loading: firstPartOfArticlesLoading }] = useLazyPromiseDatoCMSQuery(
    articlesFetchQueries[desiredArticlesType],
  );

  const [loadSecondPartOfArticles, { loading: secondPartOfArticlesLoading }] = useLazyPromiseDatoCMSQuery(
    articlesFetchQueries[desiredArticlesType],
  );

  let articlesDataLoading = firstPartOfArticlesLoading;

  if (sortingByPersonalizationTags) {
    articlesDataLoading = firstPartOfArticlesLoading || secondPartOfArticlesLoading;
  }

  const loadAllArticles = useCallback(() => {
    return loadFirstPartOfArticles({
      ssr,
      fetchPolicy: 'no-cache',
      variables: {
        take,
        skip: skip > 0 ? skip : 0,
        categories,
        tags: tags || [],
        brandTags,
        productCategoriesTags,
        personalizationTags: !isPersonalizationEnabled ? undefined : userPersonalizationTags,
        // NOTE: Using `null` instead `undefined` leads to adding empty params values,
        // such as: `/...schneider-electric.jpg?ar=&fit=&w=`
        thumbWidth: clearImage ? undefined : thumbWidth || 480,
        ar: clearImage ? undefined : '16:9',
        fit: clearImage ? undefined : 'crop',
        locale: locale || defaultLanguage,
        archiveStartDate: dateRef.current.toISOString().split('T')[0],
        sortingByPersonalizationTags: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brandTags,
    categories,
    clearImage,
    loadFirstPartOfArticles,
    locale,
    productCategoriesTags,
    skip,
    ssr,
    tags,
    take,
    thumbWidth,
    userPersonalizationTags,
  ]);

  const loadArticlesSortedByPersonalizationTags = useCallback(async () => {
    if (articlesCount < 0 || articlesWithTagsCount < 0) {
      return;
    }
    const pageIdx = paginationPageIdx - 1;
    const skip = pageIdx * take;
    const skipWithoutTag = Math.max(skip - articlesWithTagsCount, 0);
    const articlesWithoutTagsCount = articlesCount - articlesWithTagsCount;

    const numberWithTagToFetch = Math.min(take, articlesWithTagsCount - skip);
    const numberWithoutTagToFetch = Math.min(
      take - Math.max(0, numberWithTagToFetch),
      articlesWithoutTagsCount - skipWithoutTag,
    );

    return [
      ...(numberWithTagToFetch <= 0
        ? []
        : (
            await loadFirstPartOfArticles({
              ssr,
              fetchPolicy: 'no-cache',
              variables: {
                take: numberWithTagToFetch,
                skip: skip > 0 ? skip : 0,
                categories,
                tags: tags || [],
                brandTags,
                productCategoriesTags,
                personalizationTags: userPersonalizationTags,
                // NOTE: Using `null` instead `undefined` leads to adding empty params values,
                // such as: `/...schneider-electric.jpg?ar=&fit=&w=`
                thumbWidth: clearImage ? undefined : thumbWidth || 480,
                ar: clearImage ? undefined : '16:9',
                fit: clearImage ? undefined : 'crop',
                locale: locale || defaultLanguage,
                archiveStartDate: dateRef.current.toISOString().split('T')[0],
                sortingByPersonalizationTags,
              },
            })
          )?.allArticles),

      ...(numberWithoutTagToFetch <= 0
        ? []
        : (
            await loadSecondPartOfArticles({
              ssr,
              fetchPolicy: 'no-cache',
              variables: {
                skip: skipWithoutTag || 0,
                take: numberWithoutTagToFetch,
                categories,
                tags: tags || [],
                brandTags,
                productCategoriesTags,
                personalizationTags: !isPersonalizationEnabled ? undefined : userPersonalizationTags,
                // NOTE: Using `null` instead `undefined` leads to adding empty params values,
                // such as: `/...schneider-electric.jpg?ar=&fit=&w=`
                thumbWidth: clearImage ? undefined : thumbWidth || 480,
                ar: clearImage ? undefined : '16:9',
                fit: clearImage ? undefined : 'crop',
                locale: locale || defaultLanguage,
                archiveStartDate: dateRef.current.toISOString().split('T')[0],
                sortingByPersonalizationTags: false,
              },
            })
          )?.allArticles),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    articlesCount,
    articlesWithTagsCount,
    brandTags,
    categories,
    clearImage,
    loadFirstPartOfArticles,
    loadSecondPartOfArticles,
    locale,
    paginationPageIdx,
    productCategoriesTags,
    sortingByPersonalizationTags,
    ssr,
    tags,
    take,
    thumbWidth,
    userData?.tags,
    userData?.userInfo?.tags,
    isPersonalizationEnabled,
  ]);

  const fetchArticlesData = useCallback(() => {
    if (sortingByPersonalizationTags) {
      loadWithTagsArticlesCount();

      loadArticlesSortedByPersonalizationTags().then((data) => {
        setAllArticles(data);
      });
    } else {
      loadAllArticles().then((data) => setAllArticles(data.allArticles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAllArticles, loadArticlesSortedByPersonalizationTags, paginationPageIdx, sortingByPersonalizationTags]);

  useEffect(() => {
    if (articlesDataLoading || !articlesCount) {
      return;
    }
    fetchArticlesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationPageIdx, articlesWithTagsCount, articlesCount, categories]);

  useEffect(() => {
    if (articlesDataLoading || articlesCount >= 0 || !tags?.length) {
      return;
    }

    fetchArticlesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags.length]);

  const { data: articleCategoriesData, loading: articleCategoriesDataLoading } = useDatoCMSQuery(
    GET_ARTICLE_CATEGORIES_DETAILS,
    {
      ssr,
      fetchPolicy: 'no-cache',
      variables: {
        titles: categories,
        locale: locale || defaultLanguage,
      },
    },
  );

  const allCategories = articleCategoriesData?.allArticleCategories;
  const hasArticlesAndCategoriesData = allArticles && allCategories;

  if (!hasArticlesAndCategoriesData) {
    return [
      fetchArticlesData,
      {
        articles: [],
        loading: articlesDataLoading,
      },
    ];
  }

  const categoriesSlugsDict = articleCategoriesData.allArticleCategories.reduce((slugsDict, { title, page }) => {
    const dict = { ...slugsDict };

    if (!dict[title]) {
      dict[title] = {
        slug: page.slug,
        layout: page.layout,
      };
    }

    return dict;
  }, {});

  const articles = allArticles
    ?.filter((article) => !!categoriesSlugsDict[article.category])
    ?.map((article) => {
      const { layout, slug } = categoriesSlugsDict[article.category];

      return {
        ...article,
        layout,
        slug: `${slug}/${article.slug}`,
      };
    });

  return [
    fetchArticlesData,
    {
      articles,
      loading: articlesDataLoading,
    },
  ];
};
