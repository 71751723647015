import { useEffect, useRef } from 'react';
import GET_ARTICLES_COUNT from 'src/graphql/queries/getArticlesCount.gql';
import GET_ARCHIVED_ARTICLES_COUNT from 'src/graphql/queries/getArticlesArchivedCount.gql';
import GET_ACTIVE_ARTICLES_COUNT from 'src/graphql/queries/getArticlesActiveCount.gql';
import { defaultLanguage } from 'config/locales';
import * as articlesTypes from 'config/articlesTypes';
import useLazyDatoCMSQuery from '../useLazyDatoCMSQuery';
import useLanguage from '../useLanguage';

const initialNumberOfArticles = -1;

const articlesCountFetchQueries = {
  [articlesTypes.ACTIVE]: GET_ACTIVE_ARTICLES_COUNT,
  [articlesTypes.ARCHIVED]: GET_ARCHIVED_ARTICLES_COUNT,
  [articlesTypes.ALL]: GET_ARTICLES_COUNT,
};

export const useArticlesCount = ({
  categories,
  tags,
  brandTags,
  productCategoriesTags,
  sortingByPersonalizationTags,
  personalizationTags,
  desiredArticlesType,
  archiveStartDate,
}) => {
  if (!categories || !categories.length) {
    throw new Error("useArticlesWithTagsCount hook: Articles' categories have to be passed!");
  }
  const [locale] = useLanguage();
  const dateRef = useRef(new Date());

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.setUTCHours(23, 59, 59, 999);
    }
  }, []);

  const [loadArticlesCount, { data: articlesCountData, loading: articlesCountDataLoading }] = useLazyDatoCMSQuery(
    articlesCountFetchQueries[desiredArticlesType],
    {
      ssr: true,
      variables: {
        categories,
        tags: tags || [],
        locale: locale || defaultLanguage,
        brandTags,
        productCategoriesTags,
        archiveStartDate: dateRef.current.toISOString().split('T')[0],
        sortingByPersonalizationTags,
        personalizationTags,
        archiveStartDate,
      },
    },
  );

  const articlesCount = articlesCountData?._allArticlesMeta?.count;
  const articlesCountIsNumber = typeof articlesCount === 'number';

  return [
    loadArticlesCount,
    {
      articlesCount: articlesCountIsNumber ? articlesCount : initialNumberOfArticles,
      loading: articlesCountDataLoading,
    },
  ];
};
